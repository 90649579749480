// Generated by Framer (3e30e79)

import { addFonts, cx, CycleVariantState, SVG, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["D6GHTMbDm"];

const serializationHash = "framer-jDT2i"

const variantClassNames = {D6GHTMbDm: "framer-v-1nloqls"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "D6GHTMbDm", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1nloqls", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"D6GHTMbDm"} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1wx3o7"} data-framer-name={"graphic"} layout={"position"} layoutDependency={layoutDependency} layoutId={"wuy_MqRyb"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 30 14\"><path d=\"M 1.154 5.439 C 8.315 5.646 15.512 6.222 22.678 6.222 C 24.875 6.222 23.321 5.72 22.218 5.305 C 20.443 4.635 18.646 4.028 16.861 3.389 C 15.826 3.019 13.198 1.916 14.022 1.566 C 15.666 0.548 18.404 1.719 20.054 2.386 C 22.863 3.523 26.893 3.846 28.828 6.76 C 29.102 7.582 26.152 9.198 25.437 9.487 C 22.679 10.605 19.915 11.711 17.158 12.833\" fill=\"transparent\" stroke-width=\"1.73\" stroke=\"rgb(26,32,39)\" stroke-linecap=\"round\" stroke-miterlimit=\"10\"></path></svg>"} svgContentId={10270094340} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jDT2i.framer-keuzko, .framer-jDT2i .framer-keuzko { display: block; }", ".framer-jDT2i.framer-1nloqls { height: 14px; overflow: hidden; position: relative; width: 30px; }", ".framer-jDT2i .framer-1wx3o7 { flex: none; height: 14px; left: calc(50.00000000000002% - 30px / 2); position: absolute; top: calc(50.00000000000002% - 14px / 2); width: 30px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 14
 * @framerIntrinsicWidth 30
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameruWrumCmel: React.ComponentType<Props> = withCSS(Component, css, "framer-jDT2i") as typeof Component;
export default FrameruWrumCmel;

FrameruWrumCmel.displayName = "Doodle Arrow";

FrameruWrumCmel.defaultProps = {height: 14, width: 30};

addFonts(FrameruWrumCmel, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})